import { del, get, post, put, patch, uploadFile } from './api_setup'
import * as url from './url_helper'

// ** Authentification
const getUserAuthorization = email => get(url.GET_USER_AUTHORIZATION + `?email=${email}`)
const getUserAppsAccess = data => post(url.GET_USER_APPS_ACCESS, data)
const getAuthUser = (email, configs = {}) => get(url.GET_USER + email, configs)
const getAdminSupportCaseAccess = (email, configs = {}) => get(url.GET_ADMIN_SUPPORTCASE_ACCESS + email, configs)

// ** Users
const getUser = id => get(url.GET_USER + id)
const getUsers = ({ limit, page }) => get(url.GET_USERS + `${limit ? '/' + limit : ''}` + `${page ? '/' + page : ''}`)
const getUserQuery = ({ limit, page, query }) =>
  get(url.GET_USER_QUERY + `${query ? '/' + query : ''}` + `${limit ? '/' + limit : ''}` + `${page ? '/' + page : ''}`)
const changeUserRoleToAdmin = (id, data) => post(url.GET_USERS_DIRECTORY + id + '/makeAdmin', data)
const updateUser = (id, data) => put(url.GET_USERS_DIRECTORY + id + '/update', data)
const getUserStats = userId => get(url.GET_USER_STATS + userId + '/storage')

// ** Tools
const getSchemasList = id => get(url.GET_CUSTOMER_DIRECTORY + id + '/schemas/list')
const getDomainsList = id => get(url.GET_CUSTOMER_DIRECTORY + id + '/domains/list')
const getOrganizationsList = id => get(url.GET_CUSTOMER_DIRECTORY + id + '/orgunits/list')
const getVerificationsCodesList = id => get(url.GET_USERS_DIRECTORY + id + '/verificationCodes/list')

// ** Aliases
const createAliasesList = (email, data) => post(url.GET_USERS_DIRECTORY + email + '/aliases/create', data)
const getAliasesList = email => get(url.GET_USERS_DIRECTORY + email + '/aliases/list')
const deleteAliasesList = (email, alias) => del(url.GET_USERS_DIRECTORY + email + '/aliases/' + alias + '/delete')

// ** Groups
const getUserGroups = (email, page) => get(url.GET_USER_GROUPS + email + '/list/' + page)
const deleteUserGroup = (group, user) => del(url.GET_USER_GROUPS + 'group/' + group + '/user/' + user + '/delete')
const addUserGroup = (group, data) => post(url.GET_USER_GROUPS + 'user/' + group + '/add', data)
const searchUserGroups = (customerId, query) => get(url.GET_USER_GROUPS + customerId + '/list/' + query)
const createAliasGroup = (group, data) => post(url.GET_GROUP + group + '/alias/create', data)
const deleteAliasGroup = (group, alias) => del(url.GET_GROUP + group + '/alias/' + alias + '/delete')

// ** Gmail Settings
const getDelegationList = email => get(url.GET_DELEGATIONS + email + '/delegates/list')
const getDelegationListByQuery = (email, query) => get(url.GET_DELEGATIONS + email + '/delegates/getUsers/' + query)
const addDelegation = (email, data) => post(url.GET_DELEGATIONS + email + '/delegates/create', data)
const deleteDelegation = (email, delegate) => del(url.GET_DELEGATIONS + email + '/delegates/' + delegate + '/delete')
const getLabelsList = email => get(url.GET_DELEGATIONS + email + '/labels/list')
const createCriteria = (email, data) => post(url.GMAIL_SETTINGS + email + '/filters/create', data)
const createLabel = (email, data) => post(url.GET_DELEGATIONS + email + '/labels/create', data)
const deleteLabel = (email, id) => del(url.GET_DELEGATIONS + email + '/labels/' + id + '/delete')
const getPop = email => get(url.GET_DELEGATIONS + email + '/pop/get')
const updatePop = (email, data) => put(url.GET_DELEGATIONS + email + '/pop/update', data)
const getImap = email => get(url.GET_DELEGATIONS + email + '/imap/get')
const updateImap = (email, data) => put(url.GET_DELEGATIONS + email + '/imap/update', data)
const getVacationResponder = email => get(url.GET_DELEGATIONS + email + '/vacation/get')
const updateVacationResponder = (email, data) => put(url.GET_DELEGATIONS + email + '/vacation/update', data)
const getForwarding = email => get(url.GET_DELEGATIONS + email + '/forwarding/get')
const getForwardingAddress = email => get(url.GET_DELEGATIONS + email + '/forawdingAddress/list')
const updateForwarding = (email, data) => put(url.GET_DELEGATIONS + email + '/forwarding/update', data)
const getForwardingAddressByQuery = (email, query) =>
  get(url.GET_DELEGATIONS + email + '/bulkSignature/getUsers' + query)
const createForwardingAddress = (email, data) => post(url.GET_DELEGATIONS + email + '/forawdingAddress/create', data)
const deleteForwardingAddress = (email, data) => post(url.GET_DELEGATIONS + email + '/forawdingAddress/delete', data)
const getAllUsers = () => get('/users/all')

// **  Calendars
const getCalendarsList = id => get(url.GET_CALENDARS + id + '/list')
const updateCalendar = (email, id, data) => put('/calendar/' + email + '/user/' + id + '/update', data)
const createCalendar = (id, data) => post(url.GET_CALENDARS + id + '/create', data)
const deleteCalendar = (calendarId, id) => del('/calendar/' + calendarId + '/user/' + id + '/delete')

// ** Drive
const getDriveFolders = (id, rows, query) => get(url.GET_DRIVE + id + '/list/' + rows + '/?q=' + query)
const getAllSharedDrives = ({ id, rows, nextPageToken = '', query = '' }) =>
  get(
    url.GET_DRIVE +
      `${id + '/drives/list/' + rows}${nextPageToken ? '/' + nextPageToken : ''}${query ? '/?q=' + query : ''}`
  )
const getAllSharedDrivesPage = ({ id, rows, nextPageToken = '', query = '' }) =>
  get(
    url.GET_DRIVE +
      `${id + '/allDrives/list/' + rows}${nextPageToken ? '/' + nextPageToken : ''}${query ? '/?q=' + query : ''}`
  )
const getSharedDriveFiles = (id, rows, query) => get(url.GET_DRIVE + id + '/sharedDrive/list/' + rows + '?q=' + query)
const deleteFilePermanently = (userId, fileId) => del(url.GET_DRIVE + userId + '/file/' + fileId + '/delete')

// Get list users which they are having access to specific folder
const getSharedUsers = (id, folderId) => get(url.GET_DRIVE + id + '/file/' + folderId + '/listShared')
const getUserPermissionDrive = email => get(url.GET_DRIVE + email + '/about')
const createDriveFolder = (id, data) => post(url.GET_DRIVE + id + '/createFolder', data)
const trashDriveFolder = (id, folderId, data) => patch(url.GET_DRIVE + id + '/file/' + folderId + '/update', data)
const updateDriveFolder = (id, folderId, data) => patch(url.GET_DRIVE + id + '/file/' + folderId + '/update', data)
const addSharedUser = (id, folderId, data) => post(url.GET_DRIVE + id + '/file/' + folderId + '/allPermissions', data)
const addSharedUserPermission = (id, folderId, sharedUserId, data) =>
  patch(url.GET_DRIVE + id + '/file/' + folderId + '/permissions/' + sharedUserId, data)
const removeSharedUser = (id, folderId, sharedUserId) =>
  del(url.GET_DRIVE + id + '/file/' + folderId + '/permissions/' + sharedUserId)
const moveDriveFolder = (id, folderId, data) => patch(url.GET_DRIVE + id + '/file/' + folderId + '/move', data)
const getMimeTypeDriveFolders = (id, parent = 'root') =>
  get(
    url.GET_DRIVE +
      id +
      `/listFolders?q=mimeType = 'application/vnd.google-apps.folder' and "${parent}" in parents and trashed=false`
  )

// ** Groups
const getGroups = ({ limit, page }) => get(url.GET_GROUPS + `${limit ? limit : ''}` + `${page ? '/' + page : ''}`)

// Get group with query with minimal data
const getGroupsListing = () => get(url.GET_GROUP)
const getGroup = group => get(url.GET_GROUP + group)
const createGroup = data => post(url.GROUPS_DIRECTORY + 'insert', data)
const updateGroup = (group, data) => put(url.GROUPS_DIRECTORY + group + '/update', data)
const getGroupMembers = (group, rows) => get(url.GET_GROUP + group + '/groupMembers/get/' + rows)
const getGroupMemberByQuery = (group, query) => get('/group/' + group + '/members/getUsers/' + query)
const addGroupMembers = (group, data) => post(url.GET_GROUP + group + '/groupMembers/create/', data)
const deleteGroupMember = (group, id) => del(url.GET_GROUP + group + '/groupMembers/' + id + '/delete')
const getGroupSettings = group => get(url.GET_GROUP + group + '/groupSettings/get')
const updateGroupSettings = (group, data) => put(url.GET_GROUP + group + '/groupSettings/update', data)
const getGroupTemplates = () => get(url.GET_GROUPS_TEMPLATES)
const getGroupTemplate = id => get(url.GET_GROUP_TEMPLATE + id + '/get')
const createGroupTemplate = data => post(url.GET_GROUP_TEMPLATE + 'create', data)
const updateGroupTemplate = (id, data) => put(url.GET_GROUP_TEMPLATE + id + '/update', data)
const deleteGroupTemplate = id => del(url.GET_GROUP_TEMPLATE + id + '/delete')

// ** Contacts
const getContacts = (id, rows) => get(url.GET_CONTACTS + id + '/list/' + rows)
const createContact = (id, data) => post(url.GET_CONTACTS + id + '/createContact', data)
const deleteContact = (userId, contactId) => del(url.GET_CONTACTS + userId + '/delete/' + contactId)

// ** Signature Template
const getTemplates = id => get(url.CREATE_SIGNATURE + 'list2?q=' + id)
const getBulkSignatures = id => get(url.GET_BULK_SIGNATURE + 'list?q=' + id)
const getSignatureTemplateUsersPreview = rows => get(url.GET_SIGNATURE_TEMPLATE + 'get/' + rows)
const createSignatureTemplate = data => post(url.CREATE_SIGNATURE + 'create', data)
const updateSignatureTemplate = (id, data) => put(url.UPDATE_SIGNATURE + id, data)
const deleteTemplate = uid => del(url.CREATE_SIGNATURE + 'delete/' + uid)
const createBulkSignature = data => post(url.CREATE_BULK_SIGNATURE, data)

// ** Shared Drive
const getSharedDrives = (email, rows, query) =>
  get(url.SHARED_DRIVE + email + '/allDrives/list/' + rows + '?q=' + query)
const createSharedDrive = (email, data) => post(url.SHARED_DRIVE + email + '/drive/create', data)
const getSharedUsersInSharedDrive = (id, folderId) =>
  get(url.GET_DRIVE + id + '/sharedDrive/file/' + folderId + '/listShared?q=useDomainAdminAccess')
const addSharedUserToSharedDrive = (email, folderId, data) =>
  post(url.GET_DRIVE + email + '/file/' + folderId + '/allPermissions?q=sharedDrive', data)
const removeSharedUserFromSharedDrive = (id, folderId, sharedUserId) =>
  del(url.GET_DRIVE + id + '/sharedDrive/file/' + folderId + '/permissions/' + sharedUserId)
const updateSharedUserInSharedDrive = (email, folderId, sharedUserId, data) =>
  patch(url.GET_DRIVE + email + '/file/' + folderId + '/permissions/' + sharedUserId + '?q=sharedDrive', data)
const getSharedDriveSettings = (email, folderId) => get(url.GET_DRIVE + email + '/drives/' + folderId + '/get')
const updateSharedDriveSettings = (email, folderId, data) =>
  patch(url.GET_DRIVE + email + '/drives/' + folderId + '/patch', data)
const deleteSharedDriveSettings = (email, folderId) => del(url.GET_DRIVE + email + '/drive/' + folderId + '/delete')

// ** Licenses
const getLicenses = id => get(url.LICENSES + id)

// ** Support cases
const getAllCases = ({ id, status = '', priority = '', zendeskId = '' }) =>
  get(
    url.GET_ALL_CASES +
      id +
      `?${status ? 'status=' + status : ''}${priority ? '&priority=' + priority : ''}${
        zendeskId ? '&zendeskId=' + zendeskId : ''
      }`
  )
const createCase = data => post(url.CREATE_NEW_CASE, data)
const getCase = id => get(url.GET_CASE + id)
const updateCase = (id, data) => put(url.UPDATE_CASE + id, data)

export {
  getAuthUser,
  getUserAuthorization,
  getUserAppsAccess,
  getUsers,
  getUserQuery,
  getSchemasList,
  getUser,
  getDomainsList,
  getOrganizationsList,
  getVerificationsCodesList,
  changeUserRoleToAdmin,
  updateUser,
  getAliasesList,
  deleteAliasesList,
  createAliasesList,
  getUserGroups,
  deleteUserGroup,
  addUserGroup,
  searchUserGroups,
  getDelegationList,
  getDelegationListByQuery,
  addDelegation,
  deleteDelegation,
  getLabelsList,
  createLabel,
  deleteLabel,
  getPop,
  updatePop,
  getImap,
  updateImap,
  getVacationResponder,
  updateVacationResponder,
  getForwarding,
  updateForwarding,
  getForwardingAddress,
  getForwardingAddressByQuery,
  getAllUsers,
  createForwardingAddress,
  deleteForwardingAddress,
  getCalendarsList,
  updateCalendar,
  createCalendar,
  deleteCalendar,
  getDriveFolders,
  getGroups,
  createDriveFolder,
  trashDriveFolder,
  updateDriveFolder,
  moveDriveFolder,
  getMimeTypeDriveFolders,
  getGroup,
  getGroupMembers,
  getGroupMemberByQuery,
  addGroupMembers,
  deleteGroupMember,
  getGroupSettings,
  getSignatureTemplateUsersPreview,
  createSignatureTemplate,
  getTemplates,
  getSharedDrives,
  createSharedDrive,
  getLicenses,
  createAliasGroup,
  deleteAliasGroup,
  updateGroup,
  updateGroupSettings,
  getContacts,
  createContact,
  getGroupTemplates,
  deleteContact,
  deleteTemplate,
  getBulkSignatures,
  getGroupsListing,
  createBulkSignature,
  getGroupTemplate,
  getAllSharedDrives,
  getSharedUsers,
  removeSharedUser,
  addSharedUser,
  addSharedUserPermission,
  updateGroupTemplate,
  deleteGroupTemplate,
  createGroupTemplate,
  createGroup,
  getUserPermissionDrive,
  getSharedDriveFiles,
  deleteFilePermanently,
  updateSignatureTemplate,
  getSharedUsersInSharedDrive,
  addSharedUserToSharedDrive,
  removeSharedUserFromSharedDrive,
  updateSharedUserInSharedDrive,
  getSharedDriveSettings,
  updateSharedDriveSettings,
  deleteSharedDriveSettings,
  createCriteria,
  getUserStats,
  getAllCases,
  createCase,
  getCase,
  updateCase,
  getAdminSupportCaseAccess,
  getAllSharedDrivesPage
}
